const vertexShader = `
precision mediump float;
uniform float uSize;
uniform vec3 uColor;
uniform vec3 uColor2;
uniform vec3 lightPosition;
uniform vec3 uCameraPosition;
uniform float uLightIntensity;
uniform float uTime;
uniform float parallaxX;
uniform float parallaxY;
varying vec4 vColor;
attribute vec3 initialPosition;

float randomNoise(vec3 position, float time) {
    return fract(sin(dot(position.xyz, vec3(12.9898, 78.233, time))) * 43758.5453);
}

vec4 _mod289(vec4 x)
{
  return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec3 _mod289(vec3 x)
{
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec2 _mod289(vec2 x)
{
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

float _mod289(float x)
{
    return x - floor(x * (1.0 / 289.0)) * 289.0;
}

vec4 _permute(vec4 x)
{
    return _mod289(((x*34.0)+1.0)*x);
}

vec3 _permute(vec3 x)
{
    return _mod289(((x*34.0)+1.0)*x);
}

float _permute(float x)
{
    return _mod289(((x*34.0)+1.0)*x);
}

vec4 _taylorInvSqrt(vec4 r)
{
    return 1.79284291400159 - 0.85373472095314 * r;
}

float _taylorInvSqrt(float r)
{
    return 1.79284291400159 - 0.85373472095314 * r;
}

vec4 _grad4(float j, vec4 ip)
{
    const vec4 ones = vec4(1.0, 1.0, 1.0, -1.0);
    vec4 p,s;

    p.xyz = floor( fract (vec3(j) * ip.xyz) * 7.0) * ip.z - 1.0;
    p.w = 1.5 - dot(abs(p.xyz), ones.xyz);
    s = vec4(lessThan(p, vec4(0.0)));
    p.xyz = p.xyz + (s.xyz*2.0 - 1.0) * s.www;

    return p;
}


float snoise(vec2 v)
{
    const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                        0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                       -0.577350269189626,  // -1.0 + 2.0 * C.x
                        0.024390243902439); // 1.0 / 41.0
    // First corner
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);

    // Other corners
    vec2 i1;
    //i1.x = step( x0.y, x0.x ); // x0.x > x0.y ? 1.0 : 0.0
    //i1.y = 1.0 - i1.x;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    // x0 = x0 - 0.0 + 0.0 * C.xx ;
    // x1 = x0 - i1 + 1.0 * C.xx ;
    // x2 = x0 - 1.0 + 2.0 * C.xx ;
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;

    // Permutations
    i = _mod289(i); // Avoid truncation effects in permutation
    vec3 p = _permute( _permute( i.y + vec3(0.0, i1.y, 1.0 )) + i.x + vec3(0.0, i1.x, 1.0 ));

    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;

    // Gradients: 41 points uniformly over a line, mapped onto a diamond.
    // The ring size 17*17 = 289 is close to a multiple of 41 (41*7 = 287)
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;

    // Normalise gradients implicitly by scaling m
    // Approximation of: m *= inversesqrt( a0*a0 + h*h );
    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );

    // Compute final noise value at P
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
}

float snoise(vec3 v)
{
    const vec2  C = vec2(1.0/6.0, 1.0/3.0) ;
    const vec4  D = vec4(0.0, 0.5, 1.0, 2.0);

    // First corner
    vec3 i  = floor(v + dot(v, C.yyy) );
    vec3 x0 =   v - i + dot(i, C.xxx) ;

    // Other corners
    vec3 g = step(x0.yzx, x0.xyz);
    vec3 l = 1.0 - g;
    vec3 i1 = min( g.xyz, l.zxy );
    vec3 i2 = max( g.xyz, l.zxy );

    //   x0 = x0 - 0.0 + 0.0 * C.xxx;
    //   x1 = x0 - i1  + 1.0 * C.xxx;
    //   x2 = x0 - i2  + 2.0 * C.xxx;
    //   x3 = x0 - 1.0 + 3.0 * C.xxx;
    vec3 x1 = x0 - i1 + C.xxx;
    vec3 x2 = x0 - i2 + C.yyy; // 2.0*C.x = 1/3 = C.y
    vec3 x3 = x0 - D.yyy;      // -1.0+3.0*C.x = -0.5 = -D.y

    // Permutations
    i = _mod289(i);
    vec4 p = _permute( _permute( _permute(
                i.z + vec4(0.0, i1.z, i2.z, 1.0 ))
              + i.y + vec4(0.0, i1.y, i2.y, 1.0 ))
              + i.x + vec4(0.0, i1.x, i2.x, 1.0 ));

    // Gradients: 7x7 points over a square, mapped onto an octahedron.
    // The ring size 17*17 = 289 is close to a multiple of 49 (49*6 = 294)
    float n_ = 0.142857142857; // 1.0/7.0
    vec3  ns = n_ * D.wyz - D.xzx;

    vec4 j = p - 49.0 * floor(p * ns.z * ns.z);  //  mod(p,7*7)

    vec4 x_ = floor(j * ns.z);
    vec4 y_ = floor(j - 7.0 * x_ );    // mod(j,N)

    vec4 x = x_ *ns.x + ns.yyyy;
    vec4 y = y_ *ns.x + ns.yyyy;
    vec4 h = 1.0 - abs(x) - abs(y);

    vec4 b0 = vec4( x.xy, y.xy );
    vec4 b1 = vec4( x.zw, y.zw );

    //vec4 s0 = vec4(lessThan(b0,0.0))*2.0 - 1.0;
    //vec4 s1 = vec4(lessThan(b1,0.0))*2.0 - 1.0;
    vec4 s0 = floor(b0)*2.0 + 1.0;
    vec4 s1 = floor(b1)*2.0 + 1.0;
    vec4 sh = -step(h, vec4(0.0));

    vec4 a0 = b0.xzyw + s0.xzyw*sh.xxyy ;
    vec4 a1 = b1.xzyw + s1.xzyw*sh.zzww ;

    vec3 p0 = vec3(a0.xy,h.x);
    vec3 p1 = vec3(a0.zw,h.y);
    vec3 p2 = vec3(a1.xy,h.z);
    vec3 p3 = vec3(a1.zw,h.w);

    //Normalise gradients
    vec4 norm = _taylorInvSqrt(vec4(dot(p0,p0), dot(p1,p1), dot(p2, p2), dot(p3,p3)));
    p0 *= norm.x;
    p1 *= norm.y;
    p2 *= norm.z;
    p3 *= norm.w;

    // Mix final noise value
    vec4 m = max(0.6 - vec4(dot(x0,x0), dot(x1,x1), dot(x2,x2), dot(x3,x3)), 0.0);
    m = m * m;
    return 42.0 * dot( m*m, vec4( dot(p0,x0), dot(p1,x1),
                                dot(p2,x2), dot(p3,x3) ) );
}


void main() {
    // Apply some noise to the vertex position
    vec3 positionWithNoise = position;
// Generate a random value based on position and time
float randomValue = randomNoise(initialPosition, 1.0);

// Compare the random value to the noise amount to determine whether to apply noise
if (randomValue < 0.2999) {
    positionWithNoise.z += snoise(initialPosition * 500.0 + sin(uTime * 0.007)) * randomValue * 0.7;
    positionWithNoise.x += snoise(initialPosition * 500.0 + cos(uTime * 0.005)) * randomValue * 0.7;
    positionWithNoise.y += snoise(initialPosition * 500.0 + cos(uTime * 0.006)) * randomValue * 0.51;
}
    
    // Apply the adjusted size and the noise to the vertex position
    vec4 mvPosition = modelViewMatrix * vec4(positionWithNoise, 1.0);

    // Calculate the distance from the vertex to the light
    float lightDistance = distance(lightPosition, position) / uLightIntensity;

    // Calculate the distance from the vertex to the camera 

    // Calculate light intensity based on the inverse square of the distance
    float lightIntensity = 1.0 / (lightDistance * lightDistance);
    
    // Interpolate between uColor2 and uColor based on light intensity
    vColor = vec4( mix(uColor2, uColor, lightIntensity), 1.0);
    if (mod(initialPosition.x * initialPosition.x, 2.0 / 1000000.0) < 1.4 / 1000000.0) {
        vColor = vec4(uColor2,lightIntensity / 1.0);
    } else {
        vColor = vec4(uColor,lightIntensity / 1.0);
    }

    

    vec3 positionWithNoiseWorld = (modelMatrix * vec4(positionWithNoise, 1.0)).xyz;
    float cameraDistance = distance(cameraPosition, positionWithNoiseWorld) / lightIntensity;
    

    // Adjust point size based on distance from the camera
    // gl_PointSize = max(uSize * 2.0 / cameraDistance, 3.0);
    // gl_PointSize = max(uSize * (1.0 / cameraDistance), 1.0);
    gl_PointSize = max(uSize * (1.0 / cameraDistance) * 0.1  , 1.);

    //    this.startModel3D.originalModel.rotation.x += this.parallaxYSpeed * 0.01
    // this.startModel3D.originalModel.rotation.y += this.parallaxXSpeed * 0.03

    // Apply parallax effect as rotation
    // Rotation around Y axis
    float angleY = parallaxY * 0.5; // Adjust this value as needed
    mat4 rotationMatrixY = mat4(
        cos(angleY), 0.0, sin(angleY), 0.0,
        0.0, 1.0, 0.0, 0.0,
        -sin(angleY), 0.0, cos(angleY), 0.0,
        0.0, 0.0, 0.0, 1.0
    );

    // Rotation around X axis
    float angleX = parallaxX * 1.0; // Adjust this value as needed
    mat4 rotationMatrixX = mat4(
        1.0, 0.0, 0.0, 0.0,
        0.0, cos(angleX), -sin(angleX), 0.0,
        0.0, sin(angleX), cos(angleX), 0.0,
        0.0, 0.0, 0.0, 1.0
    );

    // get center of the model
    vec3 center = vec3(0.0, 0.0, 0.0);
    
        

    vec4 rotatedPosition = rotationMatrixY * rotationMatrixX * vec4(positionWithNoise - center, 1.0);

    // Apply the adjusted size and the noise to the vertex position
    mvPosition = modelViewMatrix * rotatedPosition;



    gl_Position = projectionMatrix * mvPosition;
}

`;

export default vertexShader;
