import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import './style.css'
import Model3D from './model3D';
import * as dat from "lil-gui";
import * as Stats from 'stats-js'

class ThreeScene {
    constructor() {
        this.clock = new THREE.Clock();
        this.scene = new THREE.Scene();
        this.mixer = null
        this.models3D = []

        this.scrollDivision = 5000

        this.camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.01, 1000);
        this.camera.position.z = 7;

        

        this.scene.add(this.camera);

        this.oldScrollY = 0

        this.gltfLoader = new GLTFLoader();
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('draco/');
        this.gltfLoader.setDRACOLoader(dracoLoader);
        this.ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
        this.scene.add(this.ambientLight);

        this.pointLight = new THREE.PointLight(0xffffff, 0.5);
        this.pointLight.position.set(12, 31, 41);
        this.scene.add(this.pointLight);
        this.mixers = []

        this.directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
        this.directionalLight.position.set(-12, 31, -41);
        this.scene.add(this.directionalLight);

        this.plexus = null
        this.bars = null

        this.renderer = new THREE.WebGLRenderer({
            antialias: true,
        });

        this.scene.background = new THREE.Color('#202020')

        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
        document.body.appendChild(this.renderer.domElement);

        this.scrollSpeed = 0
        this.parallaxXSpeed = 0
        this.parallaxYSpeed = 0

        this.startModel3D = null

        this.oldClientY = 0
        this.mouse = new THREE.Vector2()

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enabled = false
        this.controls.enableZoom = false
        const _ = this

        window.addEventListener('resize', () => {
            _.onResize(_);
        }, false);

        window.addEventListener('wheel', function (e) {
            _.onWheel(e, _)
        }, {
            passive: false
        });
        window.addEventListener('scroll', function (e) {
            _.onScroll(e, _)
        }, {
            passive: false
        });
        window.addEventListener('touchmove', function (e) {
            _.onTouchMove(e, _)
        });
        window.addEventListener('touchstart', function (e) {
            _.onTouchStart(e, _)
        });
        window.addEventListener('pointermove', function (e) {
            _.onPointerMove(e, _)
        });


        this.stats = new Stats();
        this.stats.showPanel(0); // 0: fps, 1: ms, 2: mb, 3+: custom
        // document.body.appendChild(this.stats.dom);

        this.load();
        this.animate();
    }
    adjustFov(_, firstTime) {
        const distance = this.camera.position.z;

        const aspect = window.innerWidth / window.innerHeight;
        
        const objectSize = 2 * 5.5;
        let fov = 2 * Math.atan((objectSize / 2) / distance) * (180 / Math.PI);
        fov = fov / ((window.innerWidth / window.innerHeight) * 3) + 25;
        this.camera.fov = fov;
        if (this.startModel3D) {
        // this.startModel3D.originalModel.position.x *= aspect / 2

        this.models3D.forEach((model) => {
       
            if (model.originalModel.name.includes('bar') || model.originalModel.name.includes('dna')) {
            } else if (model.originalModel.name.includes('brain')) {
                if (model.originalModel.userData.originalPos == undefined) {
                    model.originalModel.userData.originalPos = model.originalModel.position.clone()
                }
            } else  {
                if (model.originalModel.userData.originalPos == undefined) {
                    model.originalModel.userData.originalPos = model.originalModel.position.clone()
                }
                if (_.plexus.userData.originalPos == undefined) {
                    _.plexus.userData.originalPos = _.plexus.position.clone()
                }
                model.originalModel.position.x = model.originalModel.userData.originalPos.x * (aspect / 2)
                if (!firstTime) {
                    _.startModel3D.initialPosition.x =  _.startModel3D.originalModel.userData.originalPos.x * (aspect / 2)
                }
                
                _.plexus.position.x = _.plexus.userData.originalPos.x - (model.originalModel.userData.originalPos.x - model.originalModel.position.x)
            }
         
        })
        }
    }
     onResize(_) {
        const aspect = window.innerWidth / window.innerHeight;
        _.camera.aspect = aspect;

        const distance = _.camera.position.z;

        this.adjustFov(_)
        _.camera.updateProjectionMatrix();
        _.renderer.setSize(window.innerWidth, window.innerHeight);
    }


    onWheel(e, _) {
        e.preventDefault()
        e.stopPropagation()
        let scrollPercent = (e.deltaY) / this.scrollDivision
        _.scrollSpeed += scrollPercent
        _.oldScrollY = e.deltaY
        return false

    }
    onScroll(e, _) {
        e.preventDefault()
        e.stopPropagation()
        return false
    }

    onTouchMove(e, _) {
        let scrollPercent = (e.touches[0].clientY - _.oldClientY) / 1000
        _.oldClientY = e.touches[0].clientY

        _.scrollSpeed -= scrollPercent / 1.5
    }
    onTouchStart(e, _) {
        _.oldClientY = e.touches[0].clientY
    }
    onPointerMove(e, _) {
        var oldMouse = new THREE.Vector2(_.mouse.x, _.mouse.y)
        // -0.5, 0.5, y reverse
        _.mouse.x = e.clientX / window.innerWidth - 0.5
        _.mouse.y = -(e.clientY / window.innerHeight - 0.5)

        _.parallaxXSpeed += _.mouse.x - oldMouse.x
        _.parallaxYSpeed += _.mouse.y - oldMouse.y


    }
    async load() {
        const gui = new dat.GUI();
        gui.hide()

        const _ = this
        const [brainlogo, icoball, dnachain, bars] = await this.loadModels(['brainlogo.glb', 'icoball.glb', 'dnachain.glb', 'bars2.glb',]);
        this.startModel3D = this.models3D[0]


        // Object Surrounding Plexus
        const loader = this.gltfLoader
        await loader.load('models/icoball_v6.gltf', (gltf) => {
            gltf.scene.traverse((mesh) => {
                if (mesh.isMesh) {
                    mesh.geometry.center()
                    mesh.material = new THREE.MeshBasicMaterial({ color: 0xaaaaaa, transparent: true, opacity: 0 })
                }
            })
            _.plexus = gltf.scene.children[0]
            _.plexus.material.transparent = true
            _.plexus.geometry.setAttribute('originalPosition', _.plexus.geometry.attributes.position.clone());

            _.plexus.scale.set(0.045, 0.045, 0.045)
        // icoball.position.set(-0.8, 0.1, 4.5)

            _.plexus.position.set(-0.67, 0.18, 4.5)
            _.plexus.rotateY(Math.PI + Math.PI / 9)

            _.scene.add(_.plexus)
            this.adjustFov(this, window.innerWidth / window.innerHeight > 1);

            this.startModel3D.originalModel.position.set(this.startModel3D.initialPosition.x, this.startModel3D.initialPosition.y, this.startModel3D.initialPosition.z)
     
        this.camera.updateProjectionMatrix();

        });

        // Rest of the models
        const models = [bars, brainlogo, dnachain, icoball]

        if (this.models3D[3].animations.length > 0) {
            this.bars.visible = false
            const mixer = new THREE.AnimationMixer(this.bars);
            this.mixers.push(mixer)
            const action = mixer.clipAction(this.models3D[3].animations[0]);
            action.loop = THREE.LoopOnce;
            action.clampWhenFinished = true
            action.reset().play();

        }
        this.startModel3D.originalModel.position.set(0.6, 0.2, 5)
        this.startModel3D.originalModel.geometry.rotateZ(Math.PI / 6)
        this.startModel3D.originalModel.geometry.rotateX(Math.PI / 2)


        const params = {
            lightPosition: {
                x: 1,
                y: 1,
                z: 1,
            },
            uLightIntensity: 1,

        }
        gui.add(params.lightPosition, 'x', -100, 100).onChange((value) => {
            models.forEach((model) => {
                model.traverse((child) => {
                    if (child.material) {
                        child.material.uniforms.lightPosition.value.x = value;
                    }
                });
            }
            );

        }).listen();
        gui.add(params.lightPosition, 'y', -100, 100).onChange((value) => {
            models.forEach((model) => {
                model.traverse((child) => {
                    if (child.material) {
                        child.material.uniforms.lightPosition.value.y = value;
                    }
                });
            }
            );

        }).listen();
        gui.add(params.lightPosition, 'z', -100, 100).onChange((value) => {
            models.forEach((model) => {
                model.traverse((child) => {
                    if (child.material) {
                        child.material.uniforms.lightPosition.value.z = value;
                    }
                });
            }
            );
        }).listen();

        gui.add(params, 'uLightIntensity', 0, 100).onChange((value) => {
            models.forEach((model) => {
                model.traverse((child) => {
                    if (child.material) {
                        child.material.uniforms.uLightIntensity.value = value;
                    }
                });
            }
            );
        }).listen();

        // Bars
        bars.visible = false
        bars.position.set(1.25, -0.75, 4.8)

        bars.geometry.rotateY(Math.PI / 3)
        bars.geometry.rotateX(Math.PI / 2)
        bars.geometry.rotateZ(Math.PI / 6)
        bars.geometry.rotateX(Math.PI / 2)
        bars.geometry.center()

        // Ico Ball
        icoball.visible = false
        icoball.position.set(-0.8, 0.1, 4.5)
        icoball.geometry.scale(3, 3, 3)

        // DNA Chain
        dnachain.visible = false
        dnachain.position.set(0., 0.0, 3.5)

        dnachain.geometry.rotateY(Math.PI / 3)
        dnachain.geometry.rotateX(Math.PI / 3)
        dnachain.geometry.rotateZ(Math.PI / 6)
        dnachain.geometry.rotateX(Math.PI / 2)
        dnachain.geometry.scale(1, 1, 1)





        var neededDelta = window.scrollY / this.scrollDivision / 11
        for (var i = 0; i < 11; i++) {
            _.animateGeometries(neededDelta)

        }
        this.startModel3D.originalModel.visible = true


    }
    async loadModels(pth) {
        const paths = pth
        const scales = [0.2, 1, 0.01, 0.05, 0.4, 0.2]

        const models = []
        const indexes = []

        for (var i = 0; i < paths.length; i++) {
            const path = paths[i]
            const scale = scales[i]
            const loader = this.gltfLoader

            const modelInstance = new Model3D('models/' + path, loader);
            await modelInstance.load()
            const theModel = modelInstance.originalModel
            theModel.name = path.split('.')[0]
            models.push(modelInstance.originalModel)
            this.models3D.push(modelInstance)
            indexes.push(i)

            if (paths[i].includes('bars')) {
                this.bars = modelInstance.model
                const barsParent = new THREE.Group()
                barsParent.add(this.bars)

                this.scene.add(barsParent)
            } else if (i == 0) {
                this.scene.add(theModel);
                theModel.visible = false
            }

            theModel.scale.set(scale, scale, scale)
            theModel.position.y = -2

        }

        models.sort((a, b) => {
            return indexes.indexOf(a) - indexes.indexOf(b)
        })
        return models
    }

    animatePlexus() {
        const time = this.clock.getElapsedTime();

        this.plexus.morphTargetInfluences[0] = Math.sin(time * 2) * 0.2 + 0.25;
        this.plexus.morphTargetInfluences[1] = Math.cos(time * 2) * 0.2 + 0.25;

        this.plexus.rotation.y += this.parallaxXSpeed * 0.01

        if (this.plexus.material.opacity > 0.9) {
           this.models3D[0].originalModel.geometry.rotateY(0.002)
           this.models3D[1].originalModel.geometry.rotateY(0.002)
        } else {
        }


        const shouldIncreaseOpacity = (this.startModel3D.currentGeometryIndex == 0 && this.startModel3D.remainingDistance < 20000 && this.scrollSpeed > 0) || (this.startModel3D.currentGeometryIndex == 1 && this.startModel3D.remainingDistance > 10000 && this.scrollSpeed < 0)
        const shouldDecreaseOpacity = (this.startModel3D.currentGeometryIndex == 0 && this.scrollSpeed < 0) || (this.startModel3D.currentGeometryIndex == 1 && this.scrollSpeed > 0)

        if (shouldIncreaseOpacity) {
            if (this.plexus.material.opacity < 1) {
                this.plexus.material.opacity += Math.abs(this.scrollSpeed) * 0.3

            } else {
                this.plexus.material.opacity = 0.99
            }
        } else if (shouldDecreaseOpacity) {
            if (this.plexus.material.opacity > 0) {
                this.plexus.material.opacity -= Math.abs(this.scrollSpeed) * 0.5
            } else {
                this.plexus.material.opacity = 0
            }
        }

    }



    animate() {
        window.scrollBy(0, this.scrollSpeed * 200)

        if (this.plexus) {
            this.animatePlexus()
        }

        this.stats.update()

        this.scrollSpeed *= 0.92
        this.parallaxXSpeed *= 0.85
        this.parallaxYSpeed *= 0.85


        if (this.models3D.length == 4) {
            // if ((this.scrollSpeed > 0.0001 || this.scrollSpeed < -0.0001)) {
            if (this.startModel3D.currentGeometryIndex == 2 && this.startModel3D.remainingDistance < 200000) {
                for (var i = 0; i < this.mixers.length; i++) {
                    const currentTime = this.mixers[i]._actions[0].time
                    const paused = this.mixers[i]._actions[0].paused
                    let scrollFactor = 1 * this.scrollSpeed

                    if (currentTime == 0 && paused && this.scrollSpeed > 0) {
                        this.mixers[i]._actions[0].paused = false

                    }
                    if (this.scrollSpeed > 0) {
                        this.mixers[i].update(scrollFactor * 0.5);

                    }

                    if (this.mixers[i]._actions[0].paused && this.scrollSpeed > 0 && !this.startModel3D.finishedBarsAnimation) {
                        this.startModel3D.finishedBarsAnimation = true
                        this.startModel3D.barsFinishedPoint = this.startModel3D.currentProgress
                    }
                    if (this.scrollSpeed < 0) {
                        if (this.startModel3D.finishedBarsAnimation) {
                            this.mixers[i]._actions[0].paused = false


                        }
                        if (this.startModel3D.currentProgress < this.startModel3D.barsFinishedPoint) {
                            this.startModel3D.remainingDistance = 110
                            this.mixers[i]._actions[0].paused = false

                            if (currentTime == 0) {
                                this.startModel3D.finishedBarsAnimation = false

                            }
                            this.mixers[i].update(scrollFactor * 0.5);

                        }
                    }

                }
            }


            this.animateGeometries(this.scrollSpeed)
            // }

            this.startModel3D.originalModel.material.uniforms.parallaxX.value += this.parallaxYSpeed * 0.01
            this.startModel3D.originalModel.material.uniforms.parallaxY.value += this.parallaxXSpeed * 0.03
        }


        requestAnimationFrame(() => { this.animate() });
        this.renderer.render(this.scene, this.camera);
    }
    animateGeometries(scroll) {
        this.startModel3D.transitionTo([this.models3D[1], this.models3D[2], this.models3D[3]], scroll);
    }
    updateOpacity(opacity) {
        for (var i = 0; i < this.plexus.children[0].children.length; i++) {
            const sphere = this.plexus.children[0].children[i]
            sphere.material.opacity = opacity
        }
    }
}

new ThreeScene();