import { SimplexNoise } from "three/examples/jsm/math/SimplexNoise.js";
import { computeMorphedAttributes, mergeGeometries } from "three/examples/jsm/utils/BufferGeometryUtils.js";
import * as THREE from "three";
import fragmentShader from "./shaders/fragment";
import vertexShader from "./shaders/vertex";

class Model3D {
    constructor(url, loader) {
        this.url = url;
        this.totalParticles = 40000;
        this.particleSize = 12
        this.loader = loader;
        this.load = this.load.bind(this);
        this.model = null
        this.remainingDistance = 0;
        this.currentProgress = 0
        this.totalDelta = 0

        this.originalPositions = []

        this.clock = new THREE.Clock();
        this.modelCopy = null
        this.originalModel = null
        this.animations = null
        this.finishedBarsAnimation = false
        this.barsFinishedPoint = 0
        this.transitionProgress = 0
        this.initialPositions = []
        this.currentGeometryIndex = -1
        this.initialPosition = new THREE.Vector3(0.6, 0.2, 5)
        this.startPoint = null
        this.bars = []
        this.lastBarsScroll = 0
        this.noise = new SimplexNoise()
        this.breakpointsDelta = {
            '-1': 0.2,
            '0': 0.25,
            '1': 0.55,
            '2': 0.8,

        }

        this.breakpointsDeltaReverse = {
            '0': this.breakpointsDelta['-1'],
            '1': this.breakpointsDelta['0'],
            '2': this.breakpointsDelta['1'],
            '3': this.breakpointsDelta['2'],
        };

        this.material = new THREE.ShaderMaterial({
            vertexShader,
            fragmentShader,
            uniforms: {
                uSize: {
                    value: this.particleSize,
                },
                uTime: {
                    value: 0,
                },
                lightPosition: {
                    value: new THREE.Vector3(-3.2, 1.6, 6.6),
                },
                uColor: {
                    value: new THREE.Color(0xffffff),
                },
                uColor2: {
                    value: new THREE.Color(0x1081aa),
                },

                uLightIntensity: {
                    value: 16.6,
                },
                parallaxX: {
                    value: 0,
                },
                parallaxY: {
                    value: 0,
                }
            },
            blending: THREE.AdditiveBlending,
            depthWrite: false,
            side: THREE.DoubleSide,
        });
    }
    async load() {
        var _ = this
        const result = await new Promise((resolve, reject) => {
            _.loader.load(_.url, (gltf) => {
                _.model = gltf.scene

                _.originalModel = new THREE.Group().copy(gltf.scene, true)
                _.originalModel.position.set(this.initialPosition)
                _.animations = gltf.animations

                _.originalModel = _.mergeGeometries(gltf.scene)

                _.originalModel = _.processMesh(_.originalModel)
                _.modelCopy = new THREE.Mesh(new THREE.BufferGeometry().copy(_.originalModel.geometry), _.material)

                _.startPoint = new THREE.BufferGeometry().copy(_.originalModel.geometry)

                _.startPoint.rotateZ(Math.PI / 6)
                _.startPoint.rotateX(Math.PI / 2)
                _.originalModel.frustumCulled = false

                const res = {
                    scene: _.originalModel,
                    animations: gltf.animations,
                }

                resolve(res);
            }, undefined, (error) => {
                console.log('In load promise error');
                reject(error);
            });
        }
        );
        return result;
    }
    mergeGeometries(obj, animating) {
        const object = obj
        const geometries = [];
        const _ = this
        let hasSkinIndex = object.children.length > 2 ? object.children[3].children[0].geometry.attributes.skinIndex : false
        object.traverse((child) => {

            if (child.geometry) {
                const attributes = child.geometry.attributes;
                if (attributes.skinIndex) {

                    hasSkinIndex = true
                    child.geometry.attributes = {
                        position: attributes.position,
                        normal: attributes.normal,
                        uv: attributes.uv,
                        skinIndex: attributes.skinIndex,
                        skinWeight: attributes.skinWeight,
                    }
                    _.bars.push(child)
                    let geometryToPush = child.geometry
                    if (animating) {
                        const newPositions = this.getSkinnedVertexPositions(child).array
                        geometryToPush = new THREE.BufferGeometry().copy(child.geometry)
                        geometryToPush.setAttribute('position', new THREE.Float32BufferAttribute(newPositions, 3));
                        geometryToPush.attributes.position.needsUpdate = true;
                        var worldPos = new THREE.Vector3();
                        worldPos = child.getWorldPosition(worldPos);
                        geometryToPush.rotateY(Math.PI / 2)
                        geometryToPush.translate(worldPos.x, worldPos.y, worldPos.z)
                        if (child == object.children[3].children[0]) {
                        }

                    } else {

                    }
                    geometries.push(geometryToPush);
                } else {
                    if (hasSkinIndex) {
                        child.geometry.attributes.skinIndex = object.children[3].children[0].geometry.attributes.skinIndex
                        child.geometry.attributes.skinWeight = object.children[3].children[0].geometry.attributes.skinWeight
                    }
                    geometries.push(child.geometry);
                }

            } else {

            }
        }
        );


        if (animating) {
            const mergedGeometry = mergeGeometries(geometries);
            this.disposeGeometries(geometries);
            mergedGeometry.rotateY(Math.PI / 2)
            mergedGeometry.rotateX(Math.PI / 2)
            mergedGeometry.scale(0.3, 0.3, 0.3)
            const mergedMesh = new THREE.Points(mergedGeometry, this.material)
            mergedMesh.name = object.name;
            return mergedMesh.geometry
        } else {
            const mergedGeometry = mergeGeometries(geometries);
            this.disposeGeometries(geometries);
            const mergedMesh = new THREE.Points(mergedGeometry, this.material);
            mergedMesh.name = object.name;
            return mergedMesh
        }

    }
    disposeGeometries(geometries) {
        for (let i = 0; i < geometries.length; i++) {
            geometries[i].dispose();
        }
    }

    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            let j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]]; // swap elements
        }
        return array;
    }


    transitionTo(modelInstances, delta) {
        this.originalModel.material.uniforms.uTime.value += 0.4

        const models = modelInstances.map((modelInstance) => {
            return modelInstance.originalModel
        })

        this.totalDelta += delta

        if (!this.finishedBarsAnimation) {
        } else if (this.finishedBarsAnimation) {
            this.totalDelta = this.breakpointsDelta['2']
        }

        var limit = Math.max(document.body.scrollHeight, document.body.offsetHeight,
            document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
        this.currentProgress = window.scrollY / limit

        if (delta > 0) {
            if (this.currentProgress > this.breakpointsDelta[this.currentGeometryIndex.toString()] && !this.finishedBarsAnimation) {

                if (this.currentGeometryIndex < models.length - 1) {
                    this.currentGeometryIndex++;
                    return;

                }
            } else if (this.finishedBarsAnimation ) {
            this.lastBarsScroll = this.currentProgress

            }
        } else {
            const surplusByBars = this.lastBarsScroll - this.breakpointsDelta['1']
            const reachedBarsFinish = this.barsFinishedPoint > 0 
            if (this.currentProgress < this.breakpointsDeltaReverse[(this.currentGeometryIndex).toString()] - (reachedBarsFinish ? (0.1) : 0) && !this.finishedBarsAnimation) {
                if (this.currentGeometryIndex > -1) {
                    this.currentGeometryIndex--;
                }
                return;
            }
        }



        if (this.currentGeometryIndex == -1 && delta > 0) {
            return
        } else if (this.currentGeometryIndex == 0 && delta < 0) {

        }


        var newGeometry = this.updateAnimation(modelInstances[2])
        models[2].geometry.attributes.position = newGeometry.positions
        models[2].geometry.attributes.position.needsUpdate = true;




        const from = this.originalModel.geometry;
        let to;
        let toModel;
        if (delta > 0 || this.finishedBarsAnimation) {
            to = models[this.currentGeometryIndex].geometry;
            toModel = models[this.currentGeometryIndex]
        } else {
            if (this.currentGeometryIndex === 0 || this.currentGeometryIndex === -1) {
                to = this.startPoint
                toModel = this.modelCopy

            } else {
                to = models[this.currentGeometryIndex - 1].geometry;
                toModel = models[this.currentGeometryIndex - 1]
            }
        }
        let toModelPosition = toModel.position
        if (delta < 0 && (this.currentGeometryIndex == 0 || this.currentGeometryIndex == -1)) {
            toModelPosition = this.initialPosition
        }

        if (this.initialPositions.length < 1) {
            this.initialPositions = models.map((model) => {
                return model.geometry.attributes.position.array
            }
            )
        }

        if (from.attributes.position.count !== to.attributes.position.count) {
            console.error('The number of vertices in "from" and "to" models should be the same.');
            return;
        }
        if (this.originalModel.material.uniforms.uTime.value == 0.4) {
            this.originalPositions = models.map((model) => {
                return model.geometry.attributes.position.array
            })
        }

        let diff;
        if (delta > 0) {
            diff = to.attributes.position.array.map((value, i) =>
                (value - from.attributes.position.array[i])
            );
        } else {
            diff = from.attributes.position.array.map((value, i) =>
                (value - to.attributes.position.array[i])
            );

        }

        let newPositions = new Float32Array(from.attributes.position.array);
        let indexes = Array.from({ length: newPositions.length }, (_, i) => i);
        indexes = this.shuffleArray(indexes);
        this.remainingDistance = 0;

        let totalFrames = 100;

        for (let i = 0; i < indexes.length; i++) {
            let index = indexes[i];

            let stepSize = diff[index] / totalFrames;

            newPositions[index] += stepSize * delta * 100.0;

            this.remainingDistance += Math.abs(diff[index])

        }

        if (this.totalDelta > 30 && this.currentGeometryIndex == 1) {
            this.remainingDistance = 0

        } else if (this.currentGeometryIndex == 2 && delta < -0) {
            if (this.remainingDistance < 1000) {
                this.remainingDistance = 99

            }
        }

        // DNA Twist
        if (this.currentGeometryIndex == 1 || this.currentGeometryIndex == 2) {
            const axis = new THREE.Vector3(1, -0.2, 0);
            models[1].geometry.applyQuaternion(new THREE.Quaternion().setFromAxisAngle(axis, delta * 0.1))
        }
   
        if (delta > 0) {
            this.originalModel.position.lerp(toModelPosition, delta)

        } else {
            this.originalModel.position.lerp(toModelPosition, -delta)
        }

        this.originalModel.geometry.setAttribute('position', new THREE.BufferAttribute(newPositions, 3));
        this.originalModel.geometry.attributes.position.needsUpdate = true;
    }
    logScale(input, maxDistance) {
        const positiveInput = Math.abs(input);
        const logScaled = Math.log10(positiveInput + 1);
        return logScaled / Math.log10(maxDistance + 1);
    }
    easeInLog(t) {
        return Math.log(t + 1);
    }

    pseudoRandom(seed) {
        let x = Math.sin(seed + 1) * 10000;
        return x - Math.floor(x);

    }

    processGeometry(geometry, totalParticles) {
        let positions = [];
        let initialPositions = [];
        let vertices = geometry.getAttribute('position').array;
        let indices = geometry.getIndex().array;

        // Calculate total faces
        let totalFaces = indices.length / 3;

        // Initialize vectors for normal calculation
        let va = new THREE.Vector3();
        let vb = new THREE.Vector3();
        let vc = new THREE.Vector3();
        let cb = new THREE.Vector3();
        let ab = new THREE.Vector3();

        let counter = 1;
        let facesProcessed = 0;

        // Distribute particles to each face
        for (let i = 0; i < indices.length; i += 3) {
            // Calculate normal for each face
            va.fromArray(vertices, indices[i] * 3);
            vb.fromArray(vertices, indices[i + 1] * 3);
            vc.fromArray(vertices, indices[i + 2] * 3);
            cb.subVectors(vc, vb);
            ab.subVectors(va, vb);
            cb.cross(ab);

            // Adjust the number of particles based on the face orientation
            let faceParticles = cb.y > 0.5 ? Math.floor(totalParticles / (totalFaces * 2))
                : cb.y < -0.5 ? Math.floor(totalParticles / (totalFaces * 2))
                    : Math.floor(totalParticles / totalFaces);  // lateral faces

            for (let j = 0; j < faceParticles; j++) {
                let r1 = this.pseudoRandom(i + j + 1);
                let r2 = this.pseudoRandom(i + j + 2);
                let sqrtR1 = Math.sqrt(r1);
                let x = (1 - sqrtR1) * va.x + (sqrtR1 * (1 - r2)) * vb.x + (sqrtR1 * r2) * vc.x;
                let y = (1 - sqrtR1) * va.y + (sqrtR1 * (1 - r2)) * vb.y + (sqrtR1 * r2) * vc.y;
                let z = (1 - sqrtR1) * va.z + (sqrtR1 * (1 - r2)) * vb.z + (sqrtR1 * r2) * vc.z;

                positions.push(x, y, z);
                initialPositions.push(x, y, z);
            }
            facesProcessed += 1;
            // If all particles have been allocated, stop
            if (positions.length / 3 >= totalParticles) {
                break;
            }
        }
        let faceCounter = 0;

        // Add remaining particles to the last processed face
        while (positions.length / 3 < totalParticles) {
            // Calculate face indices
            let index = (faceCounter % facesProcessed) * 3;

            va.fromArray(vertices, indices[index] * 3);
            vb.fromArray(vertices, indices[index + 1] * 3);
            vc.fromArray(vertices, indices[index + 2] * 3);

            let r1 = this.pseudoRandom(counter);
            let r2 = this.pseudoRandom(counter + 1);
            let sqrtR1 = Math.sqrt(r1);

            let x = (1 - sqrtR1) * va.x + (sqrtR1 * (1 - r2)) * vb.x + (sqrtR1 * r2) * vc.x;
            let y = (1 - sqrtR1) * va.y + (sqrtR1 * (1 - r2)) * vb.y + (sqrtR1 * r2) * vc.y;
            let z = (1 - sqrtR1) * va.z + (sqrtR1 * (1 - r2)) * vb.z + (sqrtR1 * r2) * vc.z;

            positions.push(x, y, z);
            initialPositions.push(x, y, z);

            counter += 2;  // increment the counter for generating new pseudo-random numbers
            faceCounter += 1;  // increment the face counter to move to the next face
        }

        return {
            positions: new THREE.Float32BufferAttribute(positions, 3),
            initialPositions: new THREE.Float32BufferAttribute(initialPositions, 3),
        };
    }


    getSkinnedVertexPositions(mesh) {
        const morphedAttributes = computeMorphedAttributes(mesh)
        var worldPos = new THREE.Vector3();
        worldPos = mesh.getWorldPosition(worldPos);

        return morphedAttributes.morphedPositionAttribute

    }
    updateAnimation(model) {
        const modelModelCopy = model.model

        const mergedGeometry = this.mergeGeometries(modelModelCopy, true)
        const result = this.processGeometry(mergedGeometry, this.totalParticles)

        return result

    }

    processMesh(meshArg, justUpdate = false) {
        const mesh = meshArg
        mesh.material = this.material
        mesh.updateMatrix();
        const result = this.processGeometry(mesh.geometry, this.totalParticles)
        const geometry = new THREE.BufferGeometry()
        geometry.setAttribute('position', result.positions)
        geometry.setAttribute('initialPosition', result.initialPositions)

        let points = new THREE.Points(geometry, this.material);

        points.name = mesh.name;

        return points
    }
}

export default Model3D;